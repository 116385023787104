import React, { useState, useRef } from 'react';
import html2canvas from 'html2canvas';

const NineGridTemplate = () => {
  const [cells, setCells] = useState(Array(9).fill(''));
  const [globalFontSize, setGlobalFontSize] = useState(16); // 初始全局字體大小為 16px
  const gridRef = useRef(null);

  const handleDownload = async () => {
    if (gridRef.current) {
      const canvas = await html2canvas(gridRef.current, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
      });
      const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      const link = document.createElement('a');
      link.download = 'nine-grid-template.png';
      link.href = image;
      link.click();
    }
  };

  const adjustGlobalFontSize = (delta) => {
    setGlobalFontSize(prevSize => Math.max(8, Math.min(32, prevSize + delta))); // 限制字體大小在 8px 到 32px 之間
  };

  return (
    <div className="w-full max-w-6xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-8">九宮格模板生成器</h1>
      <div className="flex flex-col md:flex-row">
        <div 
          ref={gridRef} 
          className="relative bg-white"
          style={{ width: '640px', height: '640px' }}
        >
          {cells.map((cell, index) => {
            const row = Math.floor(index / 3);
            const col = index % 3;
            const isCenterCell = index === 4;
            return (
              <div 
                key={index} 
                className={`absolute ${isCenterCell ? 'bg-pink-200' : 'bg-gray-100'}`} 
                style={{ 
                  width: '200px', 
                  height: '200px', 
                  left: col * 208 + 8,
                  top: row * 208 + 8,
                  margin: '4px',
                  overflow: 'hidden',
                }}
              >
                <div
                  contentEditable
                  suppressContentEditableWarning
                  dangerouslySetInnerHTML={{ __html: cell }}
                  className={`w-full h-full focus:outline-none bg-transparent p-2 overflow-auto
                    ${isCenterCell ? 'text-xl font-bold text-center' : 'text-left'}`}
                  style={{
                    fontSize: isCenterCell ? '20px' : `${globalFontSize}px`, // 中心格子保持固定大小
                    lineHeight: '1.5em',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    backgroundSize: '100% 1.55em',
                    backgroundAttachment: 'local'
                  }}
                />
              </div>
            );
          })}
        </div>
        
        <div className="md:ml-8 flex flex-col md:w-1/3">
          <h2 className="text-2xl font-bold mb-4">如何使用</h2>
          <p className="mb-6">
            這個工具是我在看完九宮格書寫術後，覺得很有趣，所以寫了一個小工具，
            可以讓你快速生成九宮格的模板，最後可以下載圖片進行保存，後續可用圖片進行分享。
          </p>
            <p className="mt-2 mb-2">
              <a href="https://tzangms.com/">@tzangms</a>
            </p>
          <button 
            onClick={handleDownload}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            下載圖片
          </button>

          <div className="flex items-center mt-4 mb-4">
            <span className="mr-2">字體大小：</span>
            <button onClick={() => adjustGlobalFontSize(-1)} className="bg-gray-300 px-3 py-1 mr-2">-</button>
            <span className="mr-2">{globalFontSize}px</span>
            <button onClick={() => adjustGlobalFontSize(1)} className="bg-gray-300 px-3 py-1">+</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NineGridTemplate;